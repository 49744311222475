let config = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "forwood-macquarie-testing-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-macquarie-testing-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "eu-west-1",
        URL: "https://kj7bgvuwarhillvrysqoeslnci.appsync-api.eu-west-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://1glvphfnei.execute-api.eu-west-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://nedv8xfqg1.execute-api.eu-west-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.testing.macquarie.forwoodsafety.com",
        WEBSOCKET: "wss://hnjnmnawa0.execute-api.eu-west-1.amazonaws.com/testing"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_DZdYUBRue",
        IDENTITY_POOL_ID: "eu-west-1:12f57694-1c54-43af-8cf4-e202b922600d",
        USERPOOL_HOSTED_DOMAIN: "forwood-macquarie-id-testing",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.testing.macquarie.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.testing.macquarie.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::339713028724:role/testing-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.13",
        HOSTNAME: "id.testing.macquarie.forwoodsafety.com",
        ENV_NAME: "testing",
        COOKIE_DOMAIN: ".testing.macquarie.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.testing.macquarie.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
